<template>
  <div class="pres_iva_desglose">
    <div class="cab" style="width:100px">IVA DESGLOSE</div>
    <v-sheet v-bind="$cfg.styles.marco" >
      <div style="display:columna">
        <vllabel
          label="IVA %"
          txt="BASES"
          stilot="text-align:center;color:steelblue"
          stilol="text-align:center;flex:1 1 30%"
        >
        </vllabel>
        <div v-for="n in 5" :key="n">
          <vllabel
            v-if="pdat['base' + n].value != 0"
            :label="`${pdat['ivapor' + n].value}%`"
            :txt="pdat['base' + n].value | num(2)"
            stilot="text-align:right"
            stilol="text-align:center;flex:1 1 30%"
          >
          </vllabel>
        </div>
      </div>
    </v-sheet>
  </div>
</template>
<script>
export default {
  props: {
    pdat: { type: Object, default: null },
    edicion: { type: Boolean, default: false }
  }
};
</script>
